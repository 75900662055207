import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Alert, Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import LayoutFullpage from 'layout/LayoutFullpage';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import HtmlHead from 'components/html-head/HtmlHead';
import AuthenticationApi from '@api/auth';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const VerifyOTP = () => {
  const history = useHistory();
  const title = 'Verify OTP';
  const description = 'Verify OTP Page';
  const [loginRequesting, setLoginRequesting] = useState(false);
  const [loginError, setLoginError] = useState({
    title: '',
    message: '',
    show_error: false
  });
  const validationSchema = Yup.object().shape({
    otp: Yup.string().min(5, 'Must be of 5 chars!').required('OTP is required')
  });

  function resetErrorAlert() {
    setLoginError({ message: '', title: '', show_error: false });
  }

  const initialValues = { otp: '' };
  const onSubmit = async (values) => {
    resetErrorAlert();
    setLoginRequesting(true);
    let forgetPasssword = JSON.parse(
      localStorage.getItem('FORGOT_PASSWORD_TOKEN')
    );
    let { verification_key, email_address } = forgetPasssword;
    const { response, error } = await AuthenticationApi.verifyOtp(
      verification_key,
      email_address,
      values.otp
    );
    setLoginRequesting(false);
    if (error) {
      return setLoginError({
        title: 'Error!',
        message: error.message,
        show_error: true
      });
    }
    localStorage.removeItem('FORGOT_PASSWORD_TOKEN');
    localStorage.setItem(
      'RESET_PASSWORD_TOKEN',
      JSON.stringify({
        reset_token: response.reset_token
      })
    );
    history.push('/reset-password');
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  const leftSide = (
    <div className="min-h-100 d-flex align-items-center">
      <div className="w-100 w-lg-75 w-xxl-50">
        <div>
          <div className="mb-5">
            <h1 className="display-3 text-white">RaisingRight</h1>
          </div>
          <p className="h6 text-white lh-1-5 mb-5">
            We have integrated home schooling techniques with mainstream
            education. Here we do not design curriculum, instead we design days.
          </p>
          <div className="mb-5">
            <Button
              size="lg"
              variant="outline-white"
              href="https://www.raisingright.education/"
            >
              Learn More
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  const rightSide = (
    <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
      <div className="sw-lg-50 px-5">
        <div className="sh-11">
          <NavLink to="/">
            <div className="logo-default" />
          </NavLink>
        </div>
        <div className="mb-3">
          <h2 className="cta-1 mb-0 text-primary">Verify OTP</h2>
        </div>
        <div className="mb-5">
          <p className="h6">OTP sent to your email address.</p>
          <p className="h6">Please enter 5 digit code</p>
        </div>
        <div>
          <form
            id="forgotPasswordForm"
            className="tooltip-end-bottom"
            onSubmit={handleSubmit}
          >
            {loginError.show_error && (
              <Alert variant="danger" dismissible onClose={resetErrorAlert}>
                <Alert.Heading>{loginError.title}</Alert.Heading>
                <hr />
                <p>{loginError.message}</p>
              </Alert>
            )}
            <div className="mb-3 filled form-group tooltip-end-top">
              <CsLineIcons icon="inbox" />
              <Form.Control
                type="text"
                name="otp"
                placeholder="Enter OTP"
                value={values.otp}
                onChange={handleChange}
              />
              {errors.otp && touched.otp && (
                <div className="d-block invalid-tooltip">{errors.otp}</div>
              )}
            </div>
            <Button size="lg" type="submit">
              {loginRequesting ? 'Loading ...' : 'Verify OTP'}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <HtmlHead title={title} description={description} />
      <LayoutFullpage left={leftSide} right={rightSide} />
    </>
  );
};

export default VerifyOTP;
