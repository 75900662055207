/* eslint-disable */
import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';

const pages = {
  index: lazy(() => import('views/pages/Pages')),
  authentication: {
    login: lazy(() => import('views/pages/authentication/Login')),
    forgotPassword: lazy(() =>
      import('views/pages/authentication/ForgotPassword')
    ),
    verifyOTP: lazy(() => import('views/default/VerifyOTP')),
    resetPassword: lazy(() =>
      import('views/pages/authentication/ResetPassword')
    )
  },
  home: {
    index: lazy(() => import('views/pages/home/Home'))
  },
  wall: {
    index: lazy(() => import('views/pages/wall/Wall'))
  },
  assignments: {
    index: lazy(() => import('views/pages/assignments/Assignments'))
  },
  gallery: {
    index: lazy(() => import('views/pages/gallery/Gallery'))
  },
  miscellaneous: {
    error: lazy(() => import('views/pages/miscellaneous/Error'))
  },
  profile: {
    index: lazy(() => import('views/pages/profile/Profile'))
  },
  feedback: {
    index: lazy(() => import('views/pages/feedback/Feedback'))
  },
  batch: {
    index: lazy(() => import('views/pages/batch/Batch'))
  },
  book: {
    index: lazy(() => import('views/pages/book/Book'))
  },
  toy: {
    index: lazy(() => import('views/pages/toy/Toy'))
  }
};

const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/dashboard`
    },
    {
      path: `${appRoot}/dashboard`,
      component: pages.home.index,
      label: 'Home',
      icon: 'home',
      roles: [USER_ROLE.Admin]
    },
    {
      path: `${appRoot}/wall`,
      component: pages.wall.index,
      label: 'Wall',
      icon: 'board-1',
      roles: [USER_ROLE.Admin]
    },
    {
      path: `${appRoot}/profile`,
      label: 'menu.profile',
      icon: 'user',
      component: pages.profile.index,
      roles: [USER_ROLE.Admin],
      subs: [
        {
          path: '?basic',
          label: 'Basic Details',
          component: pages.profile.index
        },
        { path: '?parent', label: 'Parents', component: pages.profile.index },
        { path: '?batch', label: 'Batches', component: pages.profile.index },
        { path: '?badge', label: 'Badges', component: pages.profile.index }

      ]
    },

    {
      path: `${appRoot}/assignments`,
      label: 'Assignments',
      icon: 'notebook-1',
      component: pages.assignments.index,
      roles: [USER_ROLE.Admin]
    },
    {
      path: `${appRoot}/gallery`,
      label: 'Gallery',
      icon: 'image',
      component: pages.gallery.index,
      roles: [USER_ROLE.Admin]
    },
    {
      path: `${appRoot}/feedback`,
      component: pages.feedback.index,
      label: 'Feedback',
      icon: 'content',
      roles: [USER_ROLE.Admin]
    },

    {
      path: `${appRoot}/batches`,
      label: 'Batches',
      icon: 'abacus',
      component: pages.batch.index,
      customMenu: true
    },
    // {
    //   path: `${appRoot}/toys`,
    //   label: 'Toys',
    //   icon: 'puzzle',
    //   component: pages.toy.index,
    //   customMenu: true
    // },
    // {
    //   path: `${appRoot}/books`,
    //   label: 'Books',
    //   icon: 'book-open',
    //   component: pages.book.index,
    //   customMenu: true
    // }
    // {
    //   path: '/about',
    //   exact: true,
    //   label: 'About',
    //   icon: 'layout-3',
    //   to: 'https://www.raisingright.education/#about',
    //   customMenu: true
    // },
    // {
    //   path: '/founder',
    //   exact: true,
    //   label: 'Founder',
    //   icon: 'startup',
    //   to: 'https://www.raisingright.education/#founder',
    //   customMenu: true
    // },
    // {
    //   path: '/interactions',
    //   exact: true,
    //   label: 'Interactions',
    //   icon: 'pen',
    //   to: 'https://www.raisingright.education/#interactions',
    //   customMenu: true
    // }
  ],
  sidebarItems: []
};
export default routesAndMenuItems;
