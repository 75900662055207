/*jshint esversion: 11 */

import axios from 'axios';
const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_HOST_URL,
  timeout: 1000000
});

function errorHandler(error) {
  console.log('AXIOS ERROR:::', error);
  const errorObject = {
    ...error,
    message: error?.response?.data?.error || error?.message
  };
  return Promise.reject(errorObject);
}

instance.interceptors.request.use((req) => {
  const access_token = window.localStorage.getItem('ACCESS_TOKEN');
  if (access_token) {
    req.headers.Authorization = `Bearer ${access_token}`;
  }
  return req;
});

instance.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});

export default instance;
