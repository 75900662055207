/*jshint esversion: 11 */

import ApiRequest from './config';

const AuthenticationApi = {
  login: async function (email, password) {
    let response = null,
      error = null;
    try {
      const { data } = await ApiRequest.post('/child/auth/login', {
        email_address: email,
        password: password
      });
      response = data.data;
    } catch (e) {
      error = e;
    }
    return { response, error };
  },
  verify: async function () {
    let response = null,
      error = null;
    try {
      const { data } = await ApiRequest.get('/child/auth/verify');
      response = data.data;
    } catch (e) {
      error = e;
    }
    return { response, error };
  },
  sendOtp: async function (email) {
    let response = null,
      error = null;
    try {
      const { data } = await ApiRequest.post('/child/auth/send-otp', {
        email_address: email
      });
      response = data.data;
    } catch (e) {
      error = e;
    }
    return { response, error };
  },
  verifyOtp: async function (verification_key, email_address, otp_code) {
    let response = null,
      error = null;
    try {
      const { data } = await ApiRequest.post('/child/auth/verify-otp', {
        verification_key,
        email_address,
        otp_code
      });
      response = data.data;
    } catch (e) {
      error = e;
    }
    return { response, error };
  },
  resetPassword: async function (reset_token, password, confirm_password) {
    let response = null,
      error = null;
    try {
      const { data } = await ApiRequest.post('/child/auth/reset-password', {
        reset_token,
        password,
        confirm_password
      });
      response = data.data;
    } catch (e) {
      error = e;
    }
    return { response, error };
  }
};

export default AuthenticationApi;
