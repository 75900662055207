import { createSlice } from '@reduxjs/toolkit';
import { USER_ROLE } from 'constants.js';

const initialState = {
  isLogin: false,
  currentUser: {},
  loading: true
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    stopAuthenticationLoader(state) {
      state.loading = false;
    },
    setCurrentUser(state, action) {
      state.isLogin = true;
      state.currentUser = {
        ...action.payload,
        role: USER_ROLE.Admin
      };
    },
    logoutUser(state) {
      state.isLogin = false;
      state.currentUser = {};
    }
  }
});

export const { setCurrentUser, stopAuthenticationLoader, logoutUser } =
  authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;
